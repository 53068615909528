import OrderService from "../../services/OrderService";

export default () => ({
    cancelModalOpen: false,
    processing: false,
    errorMessage: "",

    async save(event) {
        event.preventDefault();
        this.processing = true;
        const formData = new FormData(event.target);
        const orderId = formData.get("orderId");
        const reason = formData.get("reason");

        try {
            let res = await OrderService.requestCancelation(orderId, reason);
            console.log(res);
            this.processing = false;
            this.closeModal();
            setTimeout(() => {
                window.location.reload();
            }, 200);
        } catch (error) {
            console.log(error.response.data);
            if (error.response?.data?.message) {
                this.errorMessage = error.response.data.message;
            }
            this.processing = false;
        }
    },

    openModal() {
        this.cancelModalOpen = true;
    },

    closeModal() {
        this.cancelModalOpen = false;
    },
});
