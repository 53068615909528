import { Api } from "../../services/Api";

export default () => ({
    // Address Saving
    addressSaving: false,
    container: null,
    form: null,

    // Address Validation
    errors: {},
    isCommerce: false,

    init() {
        this.container = this.$el;
        this.form = this.container.querySelector("form");

        this.isCommerce = this.form.getAttribute("action").includes("commerce");

        this.form.addEventListener("submit", (e) => {
            e.preventDefault();
            this.save();
        });

        // Handle Modal Save
        window.addEventListener("saveAddress", (event) => {
            this.save();
        });
    },

    async save() {
        try {
            this.$dispatch("addressSaving", {
                addressSaving: this.addressSaving,
            });

            document.querySelectorAll("div.flash-error").forEach((el) => {
                el.remove();
            });
            this.form.querySelectorAll("div.error").forEach((el) => {
                el.innerHTML = "";
            });

            this.errors = null;
            this.billingRecommendation = null;
            this.shippingRecommendation = null;

            var form = this.form;

            var data = new FormData(form);

            if (form.reportValidity) {
                form.reportValidity();
            }

            if (form.checkValidity && form.checkValidity() == false) {
                return;
            }

            this.addressSaving = true;
            this.$dispatch("addressSaving", {
                addressSaving: this.addressSaving,
            });

            let action = form.getAttribute("action");
            if (!action) {
                action = form.querySelector('input[name="action"]')?.value;
            }

            if (action.includes("commerce")) {
                var billingCountryCode = data.get(
                    "billingAddress[countryCode]"
                );

                const domesticCountries = ["US", "CA"];

                // If international billing, update gatewayId to PayPal
                if (
                    billingCountryCode &&
                    domesticCountries.includes(billingCountryCode) == false
                ) {
                    data.append("gatewayId", 2);
                }
            }

            const res = await Api.post(action, data, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            if (res.data.errors) {
                // TODO:
                this.addressSaving = false;
                this.$dispatch("addressSaving", {
                    addressSaving: this.addressSaving,
                });
                alert("An error occurred updating address.");
                console.errors(res.data);
                return;
            }

            let redirect = res.data.redirect;

            if (redirect) {
                if (redirect.indexOf("://") == -1) {
                    redirect =
                        window.location.origin + `${redirect}`.padStart(1, "/");
                }
                window.location.href = redirect;
                return;
            }
            // Just update the page so adjustments and available shipping options render
            window.location.reload();
        } catch (e) {
            console.error(e);
            this.addressSaving = false;
            this.$dispatch("addressSaving", {
                addressSaving: this.addressSaving,
            });

            if (e.response && e.response.data) {
                if (e.response.data.message) {
                    this.addFlashError(e.response.data.message);
                }
                const errors = e.response.data.errors;
                this.addFormErrors(errors);

                this.$dispatch("addressError", {
                    errors,
                });
                return;
            }

            this.addFlashError("Couldn't save address.");
        }
    },

    addFormErrors(errors) {
        const prefixes = {
            ".BillingAddress": "billingAddress",
            ".ShippingAddress": "shippingAddress",
            ".editAddresses": "",
        };

        const keys = {
            ".country-code": "countryCode",
            ".address-line-1": "addressLine1",
            ".postalCode": "postalCode",
            ".locality": "locality",
            ".administrative-area": "administrativeArea",
            // TODO: Check stateValue
        };

        for (let classname of Object.keys(prefixes)) {
            let prefix = prefixes[classname];
            for (let key of Object.keys(keys)) {
                try {
                    let kv = keys[key];

                    let error = prefix ? errors[prefix + "." + kv] : errors[kv];
                    error = (Array.isArray(error) ? error[0] : error) || "";

                    let label = this.form.querySelector(
                        classname ? `${classname} ${key}` : key
                    );

                    if (label) {
                        let errorDiv = label.querySelector("div.error");
                        if (!errorDiv) {
                            errorDiv = document.createElement("div");
                            errorDiv.classList.add("error");
                            label.appendChild(errorDiv);
                        }

                        errorDiv.innerHTML = error;

                        if (error.indexOf("Invalid address") > -1) {
                            this.$dispatch("invalid-address", {
                                addressType: prefix,
                                classname,
                            });
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
    },

    addFlashError(message) {
        // Are we on a modal?

        // If so, add the error to the modal

        // If not, add the error to the header

        const modal = document.querySelector(".address-modal-wrapper .body");

        let parent = modal
            ? modal
            : document.querySelector("header.siteHeader").parentNode;

        const sibling = document.querySelector("header.siteHeader");

        const errorDiv = document.createElement("div");
        errorDiv.classList.add("flash-error");
        errorDiv.innerHTML = `<div><strong>Error</strong>${message}</div>`;

        if (modal) {
            parent.prepend(errorDiv);
        } else {
            parent.insertBefore(errorDiv, sibling);
        }
    },
});
